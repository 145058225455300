@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400&display=swap'); */


/* body {
  font-family: 'Comfortaa', cursive;
} */
body {
  font-family: 'Poppins', sans-serif;
  color: #000000;
  background-color: #F5F5F5;
  overflow-x: hidden;
}

a:hover {
  color: #932F8C;
}

a {
  color: #932F8C;
}

a {
  text-decoration: none;
}


#listing-pagination {
  float: right;
}

#listing-pagination .page-link {
  color: #fff;
  font-weight: 600;
  background: #932F8C;
  border-color: #fff;
  cursor: pointer;
}

#listing-pagination .page-item.active .page-link{
  color: #932F8C;
  background: #fff;
}


/* main {
  min-height: 80vh;
} */

.card {
  border-radius: 5px;
  /* border-color: #F5F5F5;  */
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.header {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 25px;
}

section {
  padding-bottom: 50px;
}

#header {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 28px;
  padding-left: 100px;
  background: #fff;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,0.18);
}

.utility {
  /* margin-top: 30px; */
  margin-bottom: 30px;
}

.action_table {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.action_table .action_content {
  visibility: hidden;
  width: 94px;
  width: 200px;
  padding: 0;
  right: 15px;
  top: 5px;
  position: absolute;
  z-index: 1;
  background-color: #ebebeb;
  border-radius: 0;

}

.action_table:hover .action_content {
  visibility: visible;
  background-color: #ebebeb;
  color: #932F8C;
}

.action_table .action_container {
  padding: 0px !important;
}

.action_container a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  width: 115px;
  text-align: center;
  color: inherit;
}

.action_container .link_container {
  padding: 3px 10px;
  padding: 10px 30px;

}

.action_container .link_container:hover {
  background: #932F8C;
  color: #ebebeb;
}

.table {
  /* border: 1px solid #ced4da; */
}

.table tr {
  /* border-top: 1px solid #ced4da; */
  border-bottom: 1px solid #ced4da;
}

.table th {
  padding: 20px 15px;
  /* border: 1px solid #ced4da; */
}

.table td {
  padding: 20px 15px;
  /* border: 1px solid #ced4da; */
}

#sidebar {
  background: #932F8C;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: 300px;
  /* box-shadow: 0px 0px 3px 2px #00000012; */
}

#sidebar .admin {
  position: absolute;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  top: 600px;
  cursor: pointer;
}

#sidebar .admin-icon {
  margin-left: 76px;
  margin-right: 15px;
}

#sidebar .low-opacity {
  opacity: 0.5;
}

#sidebar .sidebar-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}

#sidebar .cursor-pointer {
  cursor: pointer;
}

#sidebar .sidebar-head {
  color: #FFFFFF;
  /* font-size: 30px; */
  margin-top: 35px;
  margin-bottom: 35px;
}

#sidebar .accordion-header {
  font-size: 18px;
  font-weight: bold;
}

#sidebar .accordion-button {
  font-size: 18px;
  font-weight: bold;
  background-color: #FFFFFF;
  color: #932F8C;
}

#sidebar .accordion-header img {
  height: 26px;
  margin-left: 36px;
}

#sidebar .accordion-item {
  background: #932F8C;
  border-color: #932F8C;
  border: #FFFFFF;
}

#sidebar .accordion-body {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
}

#sidebar .accordion-body img {
  height: 22px;
  margin-left: 58px;
}

#sidebar .sidebar-icon {
  margin-right: -20px;
  max-width: 25px;
  margin-left: 20px;
}

#sidebar .sidebar-body p {
  margin-bottom: 25px;
  cursor: pointer;
}

#sidebar .sidebar-body-icon {
  margin-right: 15px;
  max-width: 20px;
  margin-left: 40px;
}

#sidebar .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.right-container {
  padding-right: 50px;
  padding-left: 100px;
}

#sidebar .accordion-button:focus {
  box-shadow: none;
  background-color: none;
}

#sidebar .sellersuccess-logo {
  width: 100%;
  max-width: 146px;
}

#sidebar .tab-content p:last-child {
  margin-bottom: 0px!important;
}

b, strong, .fw-bold {
  font-weight: 600!important;
}

#marketing {
  background-color: #F5F5F5;
}

#marketing h2.fw-bold {
  font-size: 25px;
}

#marketing .top-utility {
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
  -webkit-align-items: center;
          align-items: center;
}

#marketing .header {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 25px;
}

#marketing .card-align-items {
  display: -webkit-flex;
  display: flex;
}

#marketing .sidebar-title {
  font-size: 30px
}

#marketing .search-input::-webkit-input-placeholder {
  color: #847585;
}

#marketing .search-input:-ms-input-placeholder {
  color: #847585;
}

#marketing .search-input::placeholder {
  color: #847585;
}

#marketing .search-sort::-webkit-input-placeholder {
  color: #847585;
}

#marketing .search-sort:-ms-input-placeholder {
  color: #847585;
}

#marketing .search-sort::placeholder {
  color: #847585;
}

.cursor-pointer {
  cursor: pointer;
}

#marketing .search-filter::-webkit-input-placeholder {
  color: #847585;
}

#marketing .search-filter:-ms-input-placeholder {
  color: #847585;
}

#marketing .search-filter::placeholder {
  color: #847585;
}

.search-sort {
  background-image: none !important;
  cursor: pointer;
}

#marketing .relative {
  position: relative;
}

#marketing .search {
  position: absolute;
  top: 10px;
  right: 20px;
}

#marketing .sort {
  position: absolute;
  top: 10px;
  right: 20px;
}

#marketing .filter {
  position: absolute;
  top: 10px;
  right: 20px;
}

#marketing .market-card {
  margin-bottom: 30px;
}

#marketing .market-card-body {
  padding-top: 30px;
  padding-bottom: 30px;
}

#marketing .serial {
  /* background-color: #000000; */
  color: #000000;
  /* position: absolute; */
  top: 0;
  /* padding-left: 20px; */
  padding-right: 20px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  /* display: flex; */
  -webkit-justify-content: center;
          justify-content: center;
  letter-spacing: 0.5px;

}

#marketing .image-preview {
  height: 100%;
  width: 100%;
  /* max-width: 220px; */
}

#marketing .no-image-preview {
  height: 100%;
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#marketing .all-list {
  font-size: 25px;
}

#marketing .list-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #932f8c;
}

#marketing .list-sub-title {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

#marketing .data-value {
  font-size: 16px;
  font-weight: 600;
}

.data-value {
  text-align: left;
}

#marketing .list-sub-title-content {
  color: #000000;
  font-weight: 700;
}

#marketing .list-sub-title-dynamic-black {
  color: #000000;
  font-weight: 700;
}

#marketing .list-sub-title-dynamic-red {
  color: #E3072D;
  font-weight: 700;
}

#marketing .list-price {
  color: #932f8c;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  /* font: normal normal bold 16px/22px Nunito Sans; */
}

#marketing .brand {
  font-size: 14px;
  color: #7D7D7D;
}

#marketing .data-container {
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#marketing .list-container {
  padding-right: 50px;
}

#marketing .edit-button {
  margin-top: 10px;
  margin-bottom: 0px !important;
}

#marketing .edit-button:hover {
  background: #FFFFFF;
  color: #932F8C;
}

#marketing .market-card-search {
  margin-bottom: 30px;
} 

.button {
  border: 2px solid #932F8C;
  background-color: #932F8C;
  color: #FFFFFF;
  padding: 7px 14px;
  font-size: 16px;
  border-radius: 0.375rem;
  cursor: pointer;
  outline: none;
}

.new-button:hover {
  background: #FFFFFF;
  color: #932F8C;
}

.view-button {
  border-color: #932F8C;
  color: #FFFFFF;
  outline: none!important;
}

.view-button:hover svg {
  color: #932F8C;
}

.view-button:hover, .back-button:hover {
  background: #FFFFFF;
  color: #932F8C;
}

.view-button-close {
  background: #FFFFFF;
  color: #932F8C;
}

.view-button-close:hover {
  background: #932F8C;
  border-color: #932F8C;
  color: #FFFFFF;
}

.view-button-delete {
  background: #DC3545;
  color: #FFFFFF;
  border-color: #DC3545;
}

.view-button-delete:hover {
  background: #FFFFFF;
  border-color: #DC3545;
  color: #DC3545;
}

.button-outline {
  border: 2px solid #932F8C;
  background-color: #FFFFFF;
  color: #932F8C;
  padding: 7px 7px;
  font-size: 16px;
  border-radius: 0.375rem;
  cursor: pointer;
}

.watch-button {
  background: #FFFFFF;
  border-color: #932F8C;
  color: #932F8C;
  width: 100%;
}

.watch-button:hover {
  background: #932F8C;
  color: #FFFFFF;
}

.watch-button:hover svg {
  /* color: #932F8C; */
  color: #fff;
}

.tags-three-dots {
  text-align: right;
}

#marketing-view .relative {
  position: relative;
}

#marketing-view .flexed-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
}

#marketing-view .date-arrow {
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
}

#marketing-view .list-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  color:#932F8C;
}

.list-head {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #932f8c;
}

#marketing-view .list-sub-title-content {
  font-size: 20px;
  font-weight: 700;
}

#marketing-view .serial {
  font-size: 16px;
  color: #000000;
}

#marketing-view .list-sub-title {
  color: #000000;
  font-size: 14px;
}

#marketing-view .list-description {
  color: #000000;
  font-size: 16px;
}

#marketing-view .list-sub-description {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}

#marketing-view .list-other-info p {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

#marketing-view .list-cotents p {
  margin-bottom: 5px;
}

#marketing-view .list-price {
  color: #932F8C;
  font-size: 20px;
  font-weight: 700;
}

#marketing-view .market-card {
  margin-bottom: 30px;
  padding-top: 10px;
}


#marketing-view .similar-listing-prices {
  font-weight: 600;
  font-size: 16px;
}

#marketing-view .gross-icon {
  height: 15px;
  width: 15px;
  background: #932F8C
}

#marketing-view .net-icon {
  height: 15px;
  width: 15px;
  background: #932F8C
}

#marketing-view .other-list-img {
  max-height: 300px;
  width: 100%;
}

#marketing-view .single-line-graph {
  text-align: center;
  height: 80px;
  width: 150px;
}

#marketing-view .card-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

/* #marketing-view .date-from {
  background-color: #F5F5F5;
  padding-left: 25px;
  padding-right: 24px;
}

#marketing-view .date-to {
  background-color: #F5F5F5;
  padding-left: 35px;
  padding-right: 34px;
} */
#marketing-view .select-date {
  position: absolute;
  /* width: 252px;
  max-width: 252px; */
  right: 13px;
  top: 42px;
}

#marketing-view .amazon-income {
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
}

#marketing-view .select-amazon-income {
  position: absolute;
  right: 13px;
  top: 42px;
  width: 90%;
}

#marketing-view .input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 70px;
}

#marketing-view .date-range::-webkit-input-placeholder {
  color: #000000;
}

#marketing-view .date-range:-ms-input-placeholder {
  color: #000000;
}

#marketing-view .date-range::placeholder {
  color: #000000;
}

#marketing-view .item-img {
  border-radius: 5px;
  width: 100%;
  max-height: 400px;
}

#marketing-view .card-body {
  padding: 20px;
}

#marketing-view .data-container {
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#marketing-view .list-container {
  padding-right: 50px;
}

#marketing-view .soc-med {
  background: #000;
  color: #fff;
  padding: 3px;
  border-radius: 50%;
  /* margin-left: 10px; */
}

#marketing-view .soc-med-container {
  margin-left: 20px;
  outline: none;
}

#marketing-view .soc-med:hover {
  color: #FFFFFF;
  background: #932f8c;
}

#marketing-view .btn-icon {
  margin-bottom: 3px;
}

#marketing-view .form-control {
  color: #000000;
}

#marketing-view .market-card-products {
  margin-bottom: 30px;
  padding-top: 3px;
  min-height: 500px;
}

#marketing-create .form-control, #marketing-create .form-select {
  /* margin-bottom: 10px; */
}

#marketing-create .remove {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 5px;
  margin-top: -42px;
}

#marketing-create .input-fields {
  position: relative !important;
}

#marketing-create .form-label {
  font-weight: 600;
}

#marketing-create .utility {
  /* padding-top: 1.5rem !important; */
  /* padding-bottom: 1.5rem !important; */
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}

#marketing-create .btn-icon {
  margin-bottom: 3px;
}

.tabs {
  border-radius: 0;
  overflow: hidden;
  box-shadow: none;
  border: none;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1em;
  background: #80167A;
  cursor: pointer;
  color: #fff;
  outline: none;
  font-size: 18px;
}

.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-top: 5px;
  color: #fff;
}
.tab-label img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}


.tab-label.no-caret::after {
  content: "";
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #ffffff;
  transition: all 0.35s;
}

.tab-close:hover {
  background: #1a252f;
}

input:checked+.tab-label::after {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

input:checked~.tab-content {
  max-height: 100vh;
  padding: 1em;
}

#chck1 {
  display: none;
}

.react-modal-close {
  border: none;
  width: 22px;
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 999;
  background: transparent;
}

.react-modal-close span {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
}

.file-upload {
  text-align: center;
  box-shadow: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  width: 100%;
  min-height: 220px;
  border: dashed #E2E2E2;
  cursor: pointer;
}

.file-upload:hover {
  cursor: pointer;
}

.file-upload p {
  color: #B6B6B6;
}

.market-image-create {
  width: 100%;
  max-width: 200px;
}

.image-card {
  min-height: 220px;
}

.image-card p {
  text-align: center;
}

.button-tags {
  width: 100%;
  border: 1px solid #CED4DA;
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  text-align: left !important;
}

.card-show-tags-hidden{
  visibility: hidden;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.card-show-tags-show {
  visibility: visible;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.login-page-container .card {
  max-width: 500px;
  width: 100%;
}

.login-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.login-card-header {
  background: #2189B1;
  color: #fff;
}

#sidebar .logout-link {
  color: #fff;
  padding-left: 17px;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  border: none;
  background: transparent;
  width: 100%;
  position: absolute;
  bottom: 30px;
  outline: none;
}

#sidebar .sidebar-icon-logout {
  /* margin-right: -20px; */
  /* max-width: 25px; */
  font-size: 25px;
  color: #fff;
  margin-left: 20px;
}

#sidebar .logout-link p {
  margin-left: 20px;
  margin-left: 28px;
}

.login-card-header {
  background: #932F8C;
  color: #fff;
}

.login-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
}

.login-card-footer {
  background: #fff;
}

#login .login-card h2 {
  font-size: 35px;
  color: #932F8C;
  font-weight: 600;
  margin-bottom: 35px;
  text-align: center;
}

#login .login-page-container .login-card {
  max-width: 430px;
  width: 100%;
  height: 540px;
  padding: 20px;
}

#login .login-inner {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}

#login .input-login {
  border-top: 0;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-bottom: 1px solid#000;
  background-color: #fff;
}

.view-button-login {
  border-color: #932F8C;
  color: #FFFFFF;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.view-button-login:hover {
  background: #FFFFFF;
  color: #932F8C;
}

.logo-container-login {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
} 

#login {
  background-image: url(/static/media/bg-login.a12d2af1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#registration {
  background-image: url(/static/media/bg-login.a12d2af1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#registration .register-input {
  border-top: 0;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-bottom: 1px solid#000;
  background-color: #fff;
}

#registration .login-page-container .registration-card {
  max-width: 430px;
  width: 100%;
  height: auto;
  padding: 20px;
}

#registration .registration-card h2 {
  font-size: 35px;
  color: #932F8C;
  font-weight: 600;
  margin-bottom: 35px;
  text-align: center;
}

#registration .register-inner {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}

#modal .cancel-btn:hover {
  color: #fff;
  background: #902e89;
}

#marketing .listing-filters {
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-picture-container {
  /* width: 150px;
  height: 150px; */
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin-left: auto;
  margin-right: auto;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px;
}

.ss-subheader {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
}

.ss_entry_content {
  margin-bottom: 0;
  line-height: 1.8;
}

.ss_col_title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

h2.ss-header {
  font-size: 22px;
}

.text-right {
  text-align: right;
}

.ss-tooltip {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
  cursor: pointer;
}

.ss-tooltip .ss-tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -65px;
  font-weight: 600;
  font-size: 14px;
}

.ss-tooltip .ss-tooltiptext:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
}

.ss-tooltip:hover .ss-tooltiptext {
  visibility: visible;
}

/* .page-container {
  padding-top: 70px;
  padding-bottom: 70px;
} */

#user-messages .ss_message_content {
  padding: 20px;
}

#user-messages .user-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  border: 1px solid #e1e1e1 !important;
  padding: 15px;
}

#user-messages .recipient-image{
  max-width: 50px;
  height: auto;
}

#user-messages .user-container h3 {
  font-size: 20px;
}

#user-messages .message-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
#user-messages .message-container p {
  margin-bottom: 0px;
}

#user-profile .edit-button:hover, #update-pass-btn:hover {
  color: #932f8c;
  background: transparent;
}

.divider {
  width: 80px;
  padding: 2px;
  background: #932F8C;
  margin-bottom: 20px;
  margin-top: 10px;
}

.divider-small {
  width: 50px;
  padding: 1px;
  background: #932F8C;
  margin-bottom: 20px;
  margin-top: 10px;
}

.action_table_container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: auto;
}

.lock-unlock-container {
  position: absolute;
  top: 15px;
  right: 20px;
  width: auto;
  cursor: pointer;
}

.muted {
  color: #939393
}

.unlock-feature {
  background-color: #efefef;
}

.unlock-feature-card {
  margin-bottom: 30px;
  min-height: 300px;
  -webkit-align-items: center;
          align-items: center;
}

.unlock-feature-inner {
display: -webkit-flex;
display: flex;
-webkit-justify-content: center;
        justify-content: center;
-webkit-align-content: center;
        align-content: center;
-webkit-align-items: center;
        align-items: center;
min-height: 250px;
}

.unlock-text {
margin-top: 10px;
margin-bottom: 0px;
}

.info-circle {
font-size: 25px;
margin-right: 10px;
}

.card-headers {
background: #fff;
}

.select-amazon-income-cursor {
cursor: pointer;
}

.hamburger-btn {
  position: fixed;
  top: 15px;
  width: 100%;
  max-width: 50px;
  left: 5px;
  z-index: 99;
  color: #000;
  display: none;
  background: none;
  border: none;
  font-size: 25px;
}

.close-btn {
  color: #000;
  background: none;
  border: none;
  font-size: 25px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.login-ss-logo-mobile {
  display: none;
}

.mobile-display {
  display: none;
}

.desktop-display {
  display: block;
}

.profile-button {
  width: 100%;
}

/**/
.login-overflow {
  overflow-y: hidden !important;
}

.profile-show {
  position: absolute;
  border: none;
  box-shadow: 0px 0px 3px 2px #00000012;
  border-radius: 0;
  right: 45px;
  width: 210px;
  z-index: 99;
}

.profile-head-info {
  padding: 15px 10px 15px 10px;
}

#header .profile-avatar {
  max-width: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.profile-info-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.user-info {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.profile-button a,
.logout-button a {
  color: #495057;
  text-decoration: none;
}

.logout-button {
  padding: 10px;
  cursor: pointer;
}

.logout-button:hover {
  background: #EDEEEF;
}

.logout-button svg {
  font-size: 20px;
  margin-bottom: 2px;
  margin-right: 5px;
  color: #495057;
}

#ss-round-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

#header .notification-show {
  position: absolute;
  width: 400px;
  top: 25px;
  right: 24px;
  height: 300px;
  z-index: 99;
  overflow-y: scroll;
}

.see-all-notif {
  background: #fff;
  cursor: pointer;
}

.unlock-text.text-small {
  font-size: 14px;
  margin-bottom: -5px;
}

#notifications .data-value {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#notifications .data-container {
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#notifications .card-show-tags-show.card, #notifications .card-show-tags-hidden.card {
  max-width: 308px;
}
#notifications p, small  {
  color: #000;
}

/**/
.tags-input-container{
  border: 1px solid #CED4DA;
  padding: 7px 10px;
  border-radius: 3px;
  width: min(80vw, 600px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 0.5em;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.tag-item{
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
}
.tag-item .close{
  height: 15px;
  width: 15px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: .5em;
  font-size: 16px;
  cursor: pointer;
}

.tags-input{
  -webkit-flex-grow: 1;
          flex-grow: 1;
  /* padding: .5em 0; */
  border: none;
  outline: none;
}

.button-listing-row {
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .hamburger-btn {
    display: block;
  }

  .hidden {
    display: none;
  }
  
  .show, .close-btn {
    display: block;
  }

  [class*=" col-"],
  [class^=col-] {
    -webkit-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
    max-width: 100% !important
  }

  .right-container {
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  #header {
    margin-bottom: 0px;
  }

  #header .notification-show {
    width: 250px;
  }

  #header .row {
    --bs-gutter-x: 0!important;
}

  .button-listing-row {
    text-align: center;
  }

  select.search-sort.form-select {
    margin-bottom: 10px;
  }

  .user-admin-info {
    text-align: center;
  }

  .user-info svg {
    display: none;
  }

  .login-ss-logo {
    display: none;
  }

  .login-ss-logo-mobile {
    display: block;
    margin-bottom: 25px;
  }

  .logo-container-login {
    height: auto;
  }

  .login-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-column-gap: 100px!important;
    -webkit-column-gap: 100px!important;
            column-gap: 100px!important;
    /* height: 100%; */
  }

  #marketing .right-container {
    padding-left: 20px!important;
    padding-right: 20px!important;
  }

  #marketing .text-end {
      text-align: left!important;
  }

  #marketing h2, #marketing h2.fw-bold {
      margin-bottom: 10px!important;
      /* padding-top: 20px!important; */
  }

  #marketing .data-container, #marketing-view .data-container, #marketing input, .ss-tooltip, #marketing-view .form-control {
      margin-bottom: 10px;
  }

  #marketing .data-container.mt-3, #marketing-view .data-container.mt-3 {
      margin-top: 0px!important;
  }

  #marketing .serial, #marketing-view .list-container {
    margin-top: 20px;
  }

  #marketing .listing-filters, #marketing-view .date-range {
    margin-top: 10px!important;
  }

  #marketing-view .view-button {
    width: 100%!important;
  }

  #marketing-view .select-date, #marketing-view .select-amazon-income {
    width: 100%;
    right: 0px;
    position: revert!important;
  }

  #marketing-view .date-arrow {
    top: 22px;
  }

  #marketing-view .card {
    margin-bottom: 30px!important;
  }

  .listing-filters .search-filter.form-control {
    margin-bottom: 0px!important;
  }

  .edit-button, #marketing .top-utility,#marketing-view .market-card.col-lg-12, #marketing-view .select-date.card .form-control {
    margin-bottom: 0px!important;
  }

  .profile-picture-container {
    height: 150px!important;
    width: 100%!important;
    /* max-width: 150px!important; */
    margin-bottom: 10px;
  }

  .action_table_container {
    right: 0px!important;
  }

  .action_table .action_content {
    top: 0px!important;
    right: 10px!important;
  }
  
  .user-name, .user-email {
    text-align: center!important;
  }

  .mobile-display {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 15px;
  }

  .desktop-display {
    display: none;
  }

  .profile-button {
    margin-bottom: 20px;
  }

  .profile-button:last-child {
    margin-bottom: 0px;
  }

  #update-pass-btn {
    width: 100%;
  }

  .tags-three-dots {
    text-align: right;
  }

  #header {
    padding-right: 0px;
  }

  #notifications .page-container.py-5 {
    padding-top: 0px!important;
  }

  #notifications .data-container {
    margin-bottom: 1rem!important;
  }

  #root .ps-0 {
    text-align: center;
  }
}

.header_action_table {
  cursor: pointer;
}

.listing-thumbnail-container {
  width: 100%;
  min-height: 250px;
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
  background-color: #fff;
  background-repeat: no-repeat;
}

.send-message-button {
  border: 0;
  background: transparent;
  outline: none;
  margin-right: 0;
  /* display: block; */
}

.messages .card-footer {
  background: transparent;
}

.user-icon-image {
  margin-left: auto;
  width: 100%;
  max-width: 60px;
  margin-right: auto;
  display: block;
  margin-bottom: 10px;
}

.comment-send .card {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 350px!important;
  /* max-width: 230px !important; */
  text-align: left;
  /* width: 90%; */
  background: #DEF7FF;
  border: 1px solid #BBECFB;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.user-image-container {
  max-width: 100px !important;
}

.comment-recieve .card {
  width: -moz-fit-content!important;
  /* width: 90%; */
  max-width: 350px!important;
  margin-bottom: 10px;
  text-align: left;
  padding: 10px 15px;
}

.comment-recieve, .comment-send {
  max-width: 350px!important;
}

.comment-send {
  float: right;
  text-align: right;
}

.comment-recieve {
  float: left;
  text-align: left;
}

.comment-auto-scroll {
  overflow-y: scroll;
  height: 500px;
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  bottom: 0;
  position: relative;
}

.comment-data {
  margin-bottom: 0;
}

.messages-small .comment-send .card {
  padding: 0;
}

.messages-small .comment-recieve .card {
  padding: 0;
}

.messages-small .comment-send {
  margin-right: 10px;
}

.messages-small .comment-recieve {
  margin-left: 10px;
}

.messages-small .comment-recieve, .messages-small .comment-send {
  max-width: 250px!important;
}

.messages-small .message-date {
  margin-bottom: 0;
}

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.font-weight-bold {
  font-weight: 700!important;
}

.header-notification {
  color: #000000;
  font-size: 14px;
}

.header-notification .font-weight-bold {
  color: #000000;
  font-size: 16px;
}

.header-notification:hover {
  color: #000000
}

.notification-count {
  position: absolute;
  background: #932F8C;
  color: #fff;
  padding: 2px 0px;
  line-height: 1.3;
  border-radius: 50px;
  right: 17px;
  top: -4px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
}

.notification-container {
  position: relative;
}

.search-filter {
  cursor: pointer;
}

.notification-list-unread {
  border-left: 2px solid #932f8c;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
}

.notification-list {
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.read-button{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.newbtntheme {
  background-color: #ffffff;
  border: 1px solid #932f8c !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px!important;
  color: #932f8c;
}

.newbtntheme:hover {
  color: #ffffff !important;
  background: #932f8c !important;
  border-color: #932f8c !important;
}

.newbtntheme:focus {
  color: #ffffff !important;
  background: #932f8c !important;
  border-color: #932f8c !important;
}
/* 
.search-input::placeholder,
.search-sort::placeholder,
.search-filter::placeholder { 
  color: #847585;
} */

.search-sort,
.choose-categories {
  color: rgba(0,0,0,0.6);
}

.ssp-tooltip {
  position: relative;
  display: inline-block;
  /* font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem; */
  /* width: 100%; */
  cursor: pointer;
}

.ssp-tooltip .ssp-tooltiptext {
  visibility: hidden;
  width: 230px;
  background-color: rgba(0, 0, 0, 0.7);;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -115px;
  font-weight: 600;
  font-size: 14px;
}

.ssp-tooltip .ssp-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  opacity: 0.7;
}

.ssp-tooltip:hover .ssp-tooltiptext {
  visibility: visible;
}

.status-row {
  width: 100px!important;
  display: inline-block!important;
  margin-right: 10px!important;
}
